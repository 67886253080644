// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-method-payment-js": () => import("./../../../src/pages/method-payment.js" /* webpackChunkName: "component---src-pages-method-payment-js" */),
  "component---src-pages-order-details-js": () => import("./../../../src/pages/order-details.js" /* webpackChunkName: "component---src-pages-order-details-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-payment-successful-js": () => import("./../../../src/pages/payment-successful.js" /* webpackChunkName: "component---src-pages-payment-successful-js" */),
  "component---src-pages-plan-js": () => import("./../../../src/pages/plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-recover-account-js": () => import("./../../../src/pages/recover-account.js" /* webpackChunkName: "component---src-pages-recover-account-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-route-js": () => import("./../../../src/pages/route.js" /* webpackChunkName: "component---src-pages-route-js" */),
  "component---src-pages-shopping-summary-js": () => import("./../../../src/pages/shopping-summary.js" /* webpackChunkName: "component---src-pages-shopping-summary-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-user-contacts-js": () => import("./../../../src/pages/user-contacts.js" /* webpackChunkName: "component---src-pages-user-contacts-js" */),
  "component---src-pages-wizard-js": () => import("./../../../src/pages/wizard.js" /* webpackChunkName: "component---src-pages-wizard-js" */),
  "component---src-templates-summary-js": () => import("./../../../src/templates/summary.js" /* webpackChunkName: "component---src-templates-summary-js" */)
}

