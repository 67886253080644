import { createStore as reduxCreateStore } from 'redux';
import persistState from 'redux-localstorage';
import { compose } from 'redux';

const reducer = (state, action) => {
  if (action.type === 'INCREMENT') {
    return Object.assign({}, state, {
      count: state.count + 1,
    });
  }

  if (action.type === 'SET_CONFIG') {
    return Object.assign({}, state, {
      config: action.data,
    });
  }

  if (action.type === 'SET_LOCATION') {
    return Object.assign({}, state, {
      locations: Object.assign({ ...state.locations, ...action.data }),
    });
  }

  if (action.type === 'SET_ROUTE') {
    return Object.assign({}, state, {
      route: action.data,
    });
  }

  if (action.type === 'SET_CURRENT_LOCATION') {
    return Object.assign({}, state, {
      currentLocation: action.data,
    });
  }

  if (action.type === 'SET_DATE') {
    return Object.assign({}, state, {
      travelDate: action.data,
    });
  }

  if (action.type === 'SET_TRANSPORT') {
    return Object.assign({}, state, {
      transport: action.data,
    });
  }

  if (action.type === 'SET_PLAN') {
    return Object.assign({}, state, {
      plan: action.data,
    });
  }

  if (action.type === 'SET_PAYMENT') {
    return Object.assign({}, state, {
      paymentMethod: action.data,
    });
  }

  if (action.type === 'ADD_CONTACT') {
    return Object.assign({}, state, {
      contacts: [...state.contacts, action.data],
    });
  }

  if (action.type === 'REMOVE_CONTACT') {
    return Object.assign({}, state, {
      contacts: [...state.contacts.filter(c => c.id !== action.data.id)],
    });
  }

  if (action.type === 'RESET_CONTACTS') {
    return Object.assign({}, state, {
      contacts: [],
    });
  }

  if (action.type === 'SET_VEHICLE') {
    return Object.assign({}, state, {
      vehicle: action.data,
    });
  }

  if (action.type === 'LOGIN') {
    const { jwt, user } = action.payload;
    return Object.assign({}, state, {
      jwt,
      user,
      isAuthenticated: true,
    });
  }

  if (action.type === 'LOGOUT') {
    return Object.assign({}, state, {
      jwt: null,
      user: {},
      isAuthenticated: false,
    });
  }

  return state;
};

const initialState = {
  count: 0,
  config: {},
  jwt: null,
  user: {},
  contacts: [],
  isAuthenticated: false,
  paymentMethod: null,
};

const enhancer = compose(
  persistState()
  //typeof window !== 'undefined' &&
  //window.__REDUX_DEVTOOLS_EXTENSION__ &&
  //window.__REDUX_DEVTOOLS_EXTENSION__()
);

const createStore = () =>
  typeof window !== 'undefined'
    ? reduxCreateStore(reducer, initialState, enhancer)
    : reduxCreateStore(reducer, initialState);

export default createStore;
