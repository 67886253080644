import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const GATSBY_API_URL = `${process.env.GATSBY_API_URL}/graphql`;

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const reduxData = JSON.parse(localStorage.getItem('redux'));
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      authorization: reduxData ? `Bearer ${reduxData.jwt}` : '',
    }
  };
});

const httpLink = new HttpLink({
  uri: GATSBY_API_URL,
  fetch
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
